import React, { Component } from "react";
import MobileOrderStyle from './mobileOrder.module.css'
import { connect } from 'react-redux';
import { Collapse, Grid, ListItem, ListItemIcon, ListItemText, List, TextField, Button } from "@material-ui/core";
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faShoppingCart} from '@fortawesome/free-solid-svg-icons';

class MobileOrder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false, 
        }
        this.handleClick = this.handleClick.bind(this)
    }

    

    handleClick() {
        this.setState({open: !this.state.open})
    }



    render() {
        const orderDiv = (
            <div style={{backgroundColor: '#fafafa', paddingBottom: '16px'}}>
                <div style={{width: '80%', margin: 'auto'}}>
                    {this.props.cart.items_in_cart.map((item, i) => {
                    return (
                        <Grid container spacing={3} key={`gird${i}`} alignItems='center' style={{textAlign:'right'}}>
                            <Grid item xs style={{textAlign: 'center'}}>
                                <img style={{maxHeight: '90px', marginLeft: this.state.isMobile ? 0 : '13px'}} src={item.img} />
                            </Grid>
                            <Grid item xs>
                                <p style={this.props.cart.promoCode ? {textTransform: 'uppercase', marginBottom: 0} : {textTransform: 'uppercase'}}>{item.name}</p>
                                {this.props.cart.promoCode ? <p style={{textTransform: 'uppercase', color: 'grey', fontSize:'12px'}}>{`Code: ${this.props.cart.promoCode}`}</p> : null}
                            </Grid>
                            <Grid item xs>
                                <p style={item.discount ? {textTransform: 'uppercase', textDecoration:'line-through', marginBottom: 0, color:'grey'} : {textTransform: 'uppercase'}}>{`$${item.price}`}</p>
                                {item.discount ? <p style={{textTransform: 'uppercase'}}>{`$${item.discountedPrice}`}</p> : null}
                            </Grid>
                        </Grid>
                    )
                    })}
                    <hr/>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        {(this.props.cart.tax === undefined || this.props.cart.shipping === undefined) ? <p style={{fontWeight:300, margin:'0px', fontSize: '0.92857em'}}>Taxes and shipping calculated at next step</p> : 
                            <div>
                                {(this.props.cart.tax !== undefined) ? 
                                <div>
                                    <p style={{fontWeight:300, margin:'0px', fontSize: '0.92857em', display: 'inline'}}>
                                        Tax
                                    </p>
                                    <p style={{fontWeight:300, margin:'0px', fontSize: '0.92857em', display: 'inline', marginLeft: '6px'}}>
                                        {'$'+this.props.cart.tax}
                                    </p> 
                                </div>
                            : null}

                            {(this.props.cart.shipping !== undefined) ? 
                                <div>
                                    {this.props.cart.shipping.total_charge < 15 ? null :
                                    <p style={{fontWeight:300, margin:'0px', fontSize: '0.92857em', display: 'inline'}}>
                                        Shipping
                                    </p>
                                    }
                                    <p style={{fontWeight:300, margin:'0px', fontSize: '0.92857em', display: 'inline', marginLeft: '6px'}}>
                                        {this.props.cart.shipping.total_charge < 15 ? 'Free Shipping' : '$'+this.props.cart.shipping.total_charge}
                                    </p> 
                                </div>
                            : null}
                            </div>
                        }
                        <br/>
                        <p style={{fontWeight:700, margin:'0px', marginLeft: '12px', fontFamily: `Montserrat,sans-serif`, display:'inline'}}>Total</p>
                        <p style={{fontWeight:700, margin:'0px', marginLeft: '12px', fontFamily: `Montserrat,sans-serif`, display:'inline'}}>{`$${this.props.cart.total}`}</p>
                    </div>
                </div>
            </div>
        )
        return (
            <div className={MobileOrderStyle.container}>
                <ListItem button onClick={this.handleClick} style={{backgroundColor: '#f5f5f5'}}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faShoppingCart}/>
                    </ListItemIcon>
                    <ListItemText primary={`$${this.props.cart.total}`} secondary={(this.state.open ? "Hide " : "Show ") + "order summary"} />
                    {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={this.state.open} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>
                        {orderDiv}
                    </List>
                </Collapse>
            </div>
        )
    }
}

const mapStatetoProps = (state) => {return {cart: state.cart}};
export default connect(mapStatetoProps, null)(MobileOrder);