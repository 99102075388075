import React, { Component } from "react";
import DesktopOrderStyle from './desktopOrder.module.css'
import { connect } from 'react-redux';
import { Grid, TextField, Button } from "@material-ui/core";
import { apply_promocode, clear_error, cal_total, remove_promocode } from '../redux/cart.redux';
import Axios from "axios";
import {API_URL} from '../../Global_Config';

class DesktopOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            total_calculated: false,
        }
    }

    componentDidMount() {
        this.setState({code: this.props.promoCode});
    }

    componentWillUnmount() {
        this.setState({total_calculated: false});
    }

    handleInput = name => event => {
        event.persist();
        this.setState(state => ({ ...state, [name]: event.target.value }));
    };

    applyPromoCode = () => {
        if (this.state.code) {
            if (!this.props.cart.shipping_address.email) {
                alert('Please enter your email first')
                return;
            }
            if (confirm('Are you sure to apply promo code on this order?'))
            this.props.apply_promocode(this.state.code, this.props.cart.shipping_address.email);
        }
    }

    removePromoCode = async() => {
        const config = {
            headers:{
                email: this.props.cart.shipping_address.email,
            }
        }
        try {
            await Axios.get(`${API_URL}/promocode/remove/${this.props.cart.promoCode.toUpperCase()}`, config)
            this.props.remove_promocode();
            this.props.cal_total();
        } catch(err) {
            console.log(err)
        }
        
    }

    componentDidUpdate() {
        if (!this.state.total_calculated) {
            this.props.cal_total();
            this.setState(state=>({...state, total_calculated: true}));
        }
        if (this.props.cart.err) {
            alert(this.props.cart.err);
            this.props.clear_error();
        }
    }

    render() {
        const promocode = (
            <div style={{marginTop: '2rem'}}>
                {!this.props.cart.promoCode ? 
                <div className={DesktopOrderStyle.promocode}>
                    <TextField variant='outlined' placeholder='Promo code' value={this.state.code} onChange={this.handleInput('code')}/>
                    <Button style={{width: 'auto'}} color='primary' onClick={this.applyPromoCode}>Apply</Button>
                </div>
                :
                <React.Fragment>
                    <Button variant='outlined' color='primary' style={{width: 'auto'}} onClick={this.removePromoCode}>Remove promocode</Button>
                </React.Fragment>
                }
            </div>
        )
        return (
            <div className={DesktopOrderStyle.orderInfo}>
                <h4 style={{marginBottom: '24px'}}>Your Order</h4>
                <hr/>
                {this.props.cart.items_in_cart.map((item, i) => {
                    return (
                        <Grid container spacing={3} key={`gird${i}`} alignItems='center'>
                            <Grid item xs={3} style={{textAlign: 'center'}}>
                                <img style={{maxHeight: '90px', marginLeft: '13px'}} src={item.img} />
                            </Grid>
                            <Grid item xs={4}>
                                <p style={this.props.cart.promoCode ? {textTransform: 'uppercase', textAlign: 'center', marginBottom: 0} : {textTransform: 'uppercase', textAlign: 'center'}}>{item.name}</p>
                                {this.props.cart.promoCode ? <p style={{textTransform: 'uppercase', textAlign: 'center', color: 'grey', fontSize:'12px'}}>{`Code: ${this.props.cart.promoCode}`}</p> : null}
                            </Grid>
                            <Grid item xs={1}>
                                <p style={{textAlign:'right'}}>{`${item.quantity}`}</p>
                            </Grid>
                            <Grid item xs={3}>
                                <p style={item.discount ? {textTransform: 'uppercase', textAlign:'right', textDecoration:'line-through', marginBottom: 0, color:'grey'} : {textTransform: 'uppercase', textAlign:'right'}}>{`$${item.price}`}</p>
                                {item.discount ? <p style={{textTransform: 'uppercase', textAlign:'right'}}>{`$${item.discountedPrice}`}</p> : null}
                            </Grid>
                        </Grid>
                    )
                })}
                <hr/>
                <div style={{width: '100%', textAlign: 'right'}}>
                        {(this.props.cart.tax === undefined || this.props.cart.shipping === undefined) ? <p style={{fontWeight:300, margin:'0px', fontSize: '0.92857em'}}>Taxes and shipping calculated at next step</p> : 
                            <div>
                                <p style={{fontWeight:300, margin:'0px', fontSize: '0.92857em', display: 'inline'}}>
                                    Tax: 
                                </p>
                                <p style={{fontWeight:300, margin:'0px', fontSize: '0.92857em', display: 'inline', marginLeft: '6px'}}>
                                    {'$'+this.props.cart.tax}
                                </p> 
                                <br/>
                                <p style={{fontWeight:300, margin:'0px', fontSize: '0.92857em', display: 'inline'}}>
                                    Shipping: 
                                </p>
                                <p style={{fontWeight:300, margin:'0px', fontSize: '0.92857em', display: 'inline', marginLeft: '6px'}}>
                                    {this.props.cart.shipping.total_charge < 15 ? 'Free' : '$'+this.props.cart.shipping.total_charge}
                                </p> 
                            </div>
                        }

                        
                    <br/>
                    <p style={{fontWeight:700, margin:'0px', marginLeft: '12px', fontFamily: `Montserrat,sans-serif`, display:'inline'}}>Total</p>
                    <p style={{fontWeight:700, margin:'0px', marginLeft: '12px', fontFamily: `Montserrat,sans-serif`, display:'inline'}}>{`$${this.props.cart.total}`}</p>
                    {this.props.showPromo ? promocode: null}
                </div>
            </div>
        )
    }
}

const mapStatetoProps = (state) => {return {cart: state.cart}};
const actionCreators = { apply_promocode, clear_error, cal_total, remove_promocode };
export default connect(mapStatetoProps, actionCreators)(DesktopOrder);