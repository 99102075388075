import React, {Component} from 'react'
import { connect } from 'react-redux';
import Layout from '../components/Layout'
import { total_updated, shipping_updated, shipping_charge_updated, cal_total, apply_promocode, clear_error, remove_promocode } from '../redux/cart.redux';
import Banner from '../components/shoppingBanner';
import CheckoutFlowIndicator from '../components/checkoutFlowIndicator'
import ShippingStyle from './shipping.module.css'
import CheckoutStyle from './checkout.module.css'
import MobileOrder from '../components/mobileOrder'
import DesktopOrder from '../components/desktopOrder'
import {country_code2} from '../components/countries'
import { navigate } from 'gatsby';
import { Grid, CircularProgress, FormControlLabel, Checkbox, Typography, TextField, Button } from '@material-ui/core';
import Axios from 'axios';
import { API_URL } from '../../Global_Config';

class Shipping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: true,
            freeShippingOption: undefined,
            paidShippingOption: undefined,
            selectedShipping: undefined,
            calculatingShipping: false,
            shippingFeched: false,
            stripe: typeof window !==`undefined` ? window.Stripe(process.env.STRIPE_PUBLISHABLE_KEY) : null,
        }
        this.handleResize = this.handleResize.bind(this);
        this.shippingQuery = this.shippingQuery.bind(this);
        this.handleShippingSelection = this.handleShippingSelection.bind(this);
        this.handlePaymentSession = this.handlePaymentSession.bind(this);
    }

    componentDidMount() {
        const windowSize = window.innerWidth;
        this.setState(state => ({...state, isMobile: windowSize < 1000}));
        window.addEventListener("resize", this.handleResize);
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    };

    handleResize = e => {
        const windowSize = window.innerWidth;
        this.setState(state => ({...state, isMobile: windowSize < 1000}));
    };

    componentDidUpdate() {
        if (!this.state.total_calculated) {
            this.props.cal_total();
            this.setState(state=>({...state, total_calculated: true}));
        }
        if (this.props.cart.err) {
            alert(this.props.cart.err);
            this.props.clear_error();
        }
    }

    applyPromoCode = () => {
        if (this.state.code) {
            if (!this.props.cart.shipping_address.email) {
                alert('Please enter your email first')
                return;
            }
            if (confirm('Are you sure to apply promo code on this order?'))
            this.props.apply_promocode(this.state.code, this.props.cart.shipping_address.email);
        }
    }

    removePromoCode = async() => {
        const config = {
            headers:{
                email: this.props.cart.shipping_address.email,
            }
        }
        try {
            await Axios.get(`${API_URL}/promocode/remove/${this.props.cart.promoCode.toUpperCase()}`, config)
            this.props.remove_promocode();
            this.props.cal_total();
        } catch(err) {
            console.log(err)
        }
        
    }

    handleChange = name => event => {
        event.persist();
        this.setState(state => ({ ...state, [name]: event.target.value, [`${name}Error`]: false }));
    };

    shippingQuery() {
        const addressObj = this.props.cart.shipping_address;
        const itemList = this.props.cart.items_in_cart.map(((item, i) => { 
            if (item.name === 'mo-band') {
                return({
                    actual_weight: 0.8,
                    length: 16.5,
                    width: 16.5,
                    height: 8,
                    category: 'accessory_battery',
                    declared_currency: 'USD',
                    declared_customs_value: item.price,
                    quantity: 1,
                })
            }
            
        }))
        // const boxConfig = {
        //     length: 16.5 * this.props.cart.items_in_cart.length,
        //     width: 16.5 * this.props.cart.items_in_cart.length,
        //     height: 8 * this.props.cart.items_in_cart.length,
        // };
        const shippingRateRequest = {
            origin_postal_code: '30319',
            origin_city: 'atlanta',
            origin_state: 'GA',
            destination_country_alpha2: country_code2[addressObj.country],
            destination_city: addressObj.city,
            destination_state: addressObj.state,
            destination_address_line_1: addressObj.addressLine1,
            destination_address_line_2: addressObj.addressLine2,
            destination_postal_code: addressObj.postalCode,
            taxes_duties_paid_by: 'Receiver',
            items: itemList,
            output_currency: 'USD'
        };
        
        const url = 'https://api.easyship.com/rate/v1/rates';
        const config = {
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${process.env.EASYSHIP_ACCESS_TOKEN}`
            }
        };
        this.setState(state=> ({...state, ['calculatingShipping']: true}));
        Axios.post(url, shippingRateRequest, config) 
        .then(function (response) {
            const free = response.data.rates.filter(e=> e.total_charge < 15);
            let paid = response.data.rates.filter(e=> e.total_charge >= 15);
            paid[0].total_charge = Math.round((paid[0].total_charge + paid[0].total_charge * 0.5 + paid[0].estimated_import_tax) * 1e2) / 1e2;
            this.setState(state=> ({...state, ['calculatingShipping']: false, ['shippingFeched']: true, ['freeShippingOption']: free[0], ['paidShippingOption']: paid[0]}));
            if (free.length > 0) {
                this.setState(state=> ({...state, ['selectedShipping']: 0}));
                this.props.shipping_updated(this.state.freeShippingOption);
                this.props.shipping_charge_updated(0);
            } else {
                this.setState(state=> ({...state, ['selectedShipping']: 1}));
                this.props.shipping_updated(this.state.paidShippingOption);
                this.props.shipping_charge_updated(this.state.paidShippingOption.total_charge);
            }
            this.props.cal_total();
        }.bind(this)).catch(function(error) {
            this.setState(state=>({...state, ['calculatingShipping']: false, ['shippingFeched']:true}));
            alert(error);
        }.bind(this))
    }

    handleShippingSelection(i) {
        this.setState(state => ({...state, ['selectedShipping']: i}))
        if (i === 0) {
            this.props.shipping_updated(this.state.freeShippingOption);
            this.props.shipping_charge_updated(0);
        } else {
            this.props.shipping_updated(this.state.paidShippingOption);
            this.props.shipping_charge_updated(this.state.paidShippingOption.total_charge);
        }
        this.props.cal_total();
    }

    handlePaymentSession = async(event) => {
        event.preventDefault();
        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        if (!this.props.cart.shipping) {
            return;
        }
        if (this.props.cart.promoCode) {
            const date = (new Date().toISOString());
            const config = {
                headers:{
                    time: date,
                }
            }
            try {
                await Axios.get(`${API_URL}/promocode/verify/${this.props.cart.promoCode.toUpperCase()}`, config);
            } catch (err) {
                alert('Coupon Expired');
                this.props.remove_promocode();
                this.props.cal_total();
                return;
            }
        }
        const url = "https://api.sonic-instruments.co/api/createCheckoutSession"
        // const url = 'http://localhost:3000/api/createCheckoutSession'
        var items = this.props.cart.items_in_cart.map(item => {
            return {name: item.name.toUpperCase(), description: item.description, amount: Math.round((item.discountedPrice ? item.discountedPrice : item.price) * 100), currency: 'usd', quantity: item.quantity};
        })
        if (this.props.cart.tax > 0) {
            items.push({name:'Tax', amount: Math.round(this.props.cart.tax * 100), quantity: 1, currency: 'usd'});
        }
        if (this.props.cart.shipping_charge > 0) {
            items.push({name:'Shipping', amount: Math.round((this.props.cart.discounted_shipping_charge ? this.props.cart.discounted_shipping_charge : this.props.cart.shipping_charge) * 100), quantity: 1, currency: 'usd'});
        }
        const request = {
            name: this.props.cart.shipping_address.name,
            email: this.props.cart.shipping_address.email,
            address: {
                line1: this.props.cart.shipping_address.addressLine1,
                line2: this.props.cart.shipping_address.addressLine2,
                city: this.props.cart.shipping_address.city,
                state: this.props.cart.shipping_address.state,
                country: country_code2[this.props.cart.shipping_address.country],
                postal_code: this.props.cart.shipping_address.postalCode,
            },
            items_in_cart : items,
        }
        const session = await Axios.post(url, request, config);
        if (this.state.stripe !== null) {
            const { error } = await this.state.stripe.redirectToCheckout({
                'sessionId': session.data.id,
            })
            if (error) {
                console.warn("Error:", error)
            }
        }
    }


    render() {
        if (!this.state.shippingFeched && !this.state.calculatingShipping && this.props.cart.shipping_address) {
            this.shippingQuery();
        }
        const isMobile = this.state.isMobile;
        const address = this.props.cart.shipping_address;
        const addressParsing = () => {
            const addressStr1 = address.addressLine1 + ", " + (address.addressLine2 ? (address.addressLine2 + ", ") : "") + address.city + " " + address.state;
            let country = address.country;
            const addressStr2 = " " + address.postalCode + ", " + country;
            return [addressStr1, addressStr2]
        }
        const infoBlock = (
            <div className={ShippingStyle.infoBlock}>
                <div className={ShippingStyle.infoBlockItem}>
                    <Grid container justify='flex-end' style={{paddingBottom: '0.28571em'}}>
                        <Grid item xs={2}>
                            <p className={ShippingStyle.infoBlockHeading}>
                                Contact
                            </p>
                        </Grid>
                        <Grid item xs={10} style={{textAlign:'right'}}>
                            <button className={ShippingStyle.changeBtn} onClick={()=>{navigate('/checkout')}}>
                                Change
                            </button>
                        </Grid>
                    </Grid>
                    <p className={ShippingStyle.infoBlockContent}>
                        {this.props.cart.shipping_address.email}
                    </p>
                </div>
                <hr/>
                <div className={ShippingStyle.infoBlockItem}>
                    <Grid container justify='flex-end' style={{paddingBottom: '0.28571em'}}>
                        <Grid item xs={2}>
                            <p className={ShippingStyle.infoBlockHeading}>
                                Ship to
                            </p>
                        </Grid>
                        <Grid item xs={10} style={{textAlign:'right'}}>
                            <button className={ShippingStyle.changeBtn} onClick={()=>{navigate('/checkout')}}>
                                Change
                            </button>
                        </Grid>
                    </Grid>
                    <p className={ShippingStyle.infoBlockContent}>
                        {addressParsing()[0]}
                    </p>
                    <p className={ShippingStyle.infoBlockContent}>
                        {addressParsing()[1]}
                    </p>
                </div>
            </div>
        )

        const shippingBlock = () => {
            const free = this.state.freeShippingOption;
            const paid = this.state.paidShippingOption;
            return (
                <div className={ShippingStyle.shippingBlock}>
                    {free ? 
                        <div className={ShippingStyle.shippingBlockItem}>
                            <FormControlLabel
                                value='free'
                                label={<Typography style={{fontSize: '14px'}}>Free shipping</Typography>}
                                control={
                                    <Checkbox color='primary' checked={this.state.selectedShipping === 0} onChange={() => {this.handleShippingSelection(0)}}/>
                                }
                            />
                        </div> 
                        : null
                    }
                    {paid ? <hr/> : null}
                    {paid ? 
                        <div className={ShippingStyle.shippingBlockItem}>
                            <FormControlLabel
                                value='free'
                                label={
                                    <div>
                                        <Typography style={{fontSize: '14px'}}>{paid.courier_name}</Typography>
                                        <Typography style={{fontSize: '14px'}}>{`${paid.min_delivery_time}-${paid.max_delivery_time} business days`}</Typography>
                                        <Typography style={{fontSize: '14px'}}>{`+ $${paid.total_charge}`}</Typography>
                                    </div>
                                }
                                control={
                                    <Checkbox color='primary' checked={this.state.selectedShipping === 1} onChange={() => {this.handleShippingSelection(1)}}/>
                                }
                            />
                        </div> : null
                    }
                    
                </div>
            )
        }
        return (
            <Layout hideSocial={true} hideCart={true}>
                <div>
                    <Banner isMobile={isMobile} />
                    {isMobile ? <MobileOrder /> : null}
                    <div className={CheckoutStyle.rightBackground}/>
                    <div className={CheckoutStyle.navContainer}>
                        <CheckoutFlowIndicator location='shipping' />
                    </div>
                    <div className={ShippingStyle.container}>
                        <div className={ShippingStyle.infoBlockContainer}>
                            {infoBlock}
                            {this.state.isMobile ? 
                            <div style={{marginTop: '2rem', marginBottom:'2rem'}}>
                                <h4>Coupon</h4>
                                {this.props.cart.promoCode ? 
                                <React.Fragment>
                                    <Button variant='outlined' color='primary' style={{width: 'auto'}} onClick={this.removePromoCode}>Remove promocode</Button>
                                </React.Fragment>
                                : 
                                <div style ={{display: 'flex', alignItems: 'center'}} className={ShippingStyle.promo}>
                                    <TextField variant='outlined' placeholder='Promo code' value={this.state.code} onChange={this.handleChange('code')} style={{width:'70%'}}/>
                                    <Button style={{width: '30%'}} color='primary' onClick={this.applyPromoCode}>Apply</Button>
                                </div>
                                }
                            </div> : null}
                            <h3>Shipping Method</h3>
                            {this.state.calculatingShipping ? <CircularProgress /> : null}
                            {shippingBlock()}
                            <Grid container justify={this.state.isMobile ? 'center':'space-between'} alignItems='center'>
                                <Grid item xs={this.state.isMobile ? 12 : null}>
                                    {this.state.isMobile ? <button className={CheckoutStyle.shippingBtn} onClick={this.handlePaymentSession}>Continue to payment</button>:<button className={CheckoutStyle.backBtn} onClick={()=>navigate('/checkout')}>Back to Information</button>}
                                </Grid>
                                <Grid item xs style={this.state.isMobile ? {textAlign:'center'} : {textAlign:'right'}}>
                                {this.state.isMobile ? <button className={CheckoutStyle.backBtn} onClick={()=>navigate('/checkout')}>Back to Information</button>:<button className={CheckoutStyle.shippingBtn} onClick={this.handlePaymentSession}>Continue to payment</button>}
                                </Grid>
                            </Grid>
                        </div>
                        {this.props.cart.items_in_cart.length > 0 && (!this.state.isMobile || this.props.cart.shipping) !==undefined ? <DesktopOrder showPromo={true}/> : null}
                    </div>
                </div>
            </Layout>
        )
    }
}

const mapStatetoProps = (state) => {return {cart: state.cart}};
const actionCreators = { total_updated, shipping_updated, shipping_charge_updated, cal_total, apply_promocode, clear_error, remove_promocode };
export default connect(mapStatetoProps, actionCreators)(Shipping);
