import React from 'react';
import BannerStyle from './shoppingBanner.module.css'
import { faShippingFast, faHandHoldingHeart, faComment, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Banner(props) {
    const banner = (
        <div className={BannerStyle.banner}>
            <div className={BannerStyle.bannerContainer}>
                <Grid container spacing={3}>
                    <Grid item xs className={BannerStyle.bannerItem}>
                        <FontAwesomeIcon icon={faShippingFast} size='lg'/>
                        <p>Free shipping in the U.S.</p>
                    </Grid>
                    <Grid item xs className={BannerStyle.bannerItem}>
                        <FontAwesomeIcon icon={faHandHoldingHeart} size='lg'/>
                        <p>30-day money back guarantee</p>
                    </Grid>
                    <Grid item xs className={BannerStyle.bannerItem}>
                        <FontAwesomeIcon icon={faComment} size='lg'/>
                        <p>Lifetime support from us</p>
                    </Grid>
                    <Grid item xs className={BannerStyle.bannerItem}>
                        <FontAwesomeIcon icon={faCheckCircle} size='lg'/>
                        <p>All products under warranty</p>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
    const bannerMobile = (
        <div className={BannerStyle.banner}>
            <div className={BannerStyle.bannerItem}>
                <div className={BannerStyle.iconContainer}>
                    <FontAwesomeIcon icon={faShippingFast} size='16px'/>
                </div>
                <p>Free shipping in the U.S.</p>
            </div>
            <div className={BannerStyle.bannerItem}>
                <div className={BannerStyle.iconContainer}>
                    <FontAwesomeIcon icon={faHandHoldingHeart} size='16px'/>
                </div>
                <p>30-day money back guarantee</p>
            </div>
            <div className={BannerStyle.bannerItem}>
                <div className={BannerStyle.iconContainer}>
                    <FontAwesomeIcon icon={faComment} size='16px'/>
                </div>
                <p>Lifetime support from us</p>
            </div>
            <div className={BannerStyle.bannerItem}>
                <div className={BannerStyle.iconContainer}>
                    <FontAwesomeIcon icon={faCheckCircle} size='16px'/>
                </div>
                <p>All products under warrenty</p>
            </div>
        </div>
    )
    return(
        <div>
            {props.isMobile ? bannerMobile : banner}
        </div>
    )
}

export default Banner;