import React from "react";
import CFIStyle from './checkoutFlowIndicator.module.css'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import { check_needs_shipping } from "../redux/cart.redux";

class CheckoutFlowIndicator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {needs_shipping_decided: false};
    }

    componentDidUpdate() {
        if (!this.state.needs_shipping_decided && this.props.cart.items_in_cart) {
            this.props.check_needs_shipping(this.props.cart.items_in_cart);
            this.setState({needs_shipping_decided: true});
        }
    }

    render() {
        const props = this.props;
        return (
            <div className={CFIStyle.container}>
                <div className={props.location === 'cart' ? CFIStyle.on : CFIStyle.off}>
                    <Link to='/cart'>Cart</Link>
                </div>
                <div className={CFIStyle.icon}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
                <div className={props.location === 'info' ? CFIStyle.on : props.location === 'cart' ? CFIStyle.highLight : CFIStyle.off}>
                    {(props.cart.items_in_cart.length > 0 && props.cart.total) ? <Link to='/checkout'>Information</Link> : <p>Information</p>}
                </div>
                <div className={CFIStyle.icon}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </div>
                {props.cart.needs_shipping ? 
                    <React.Fragment>
                        <div className={props.location === 'shipping' ? CFIStyle.on : props.location === 'info' ? CFIStyle.highLight : CFIStyle.off}>
                            <p>Shipping</p>
                        </div>
                        <div className={CFIStyle.icon}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                    </React.Fragment>
                : null}
                
                <div className={props.location === 'checkout' ? CFIStyle.on : props.location === 'shipping' ? CFIStyle.highLight : CFIStyle.off}>
                    <p>Payment</p>
                </div>
            </div>
        )
    }
}


const mapStatetoProps = (state) => {return {cart: state.cart}};
const actionCreators = { check_needs_shipping }
export default connect(mapStatetoProps, actionCreators)(CheckoutFlowIndicator);